.chatlog {
  border: 1px solid transparent;
  width: 100%;

  font-size: 1.5rem;

  tr.message {
    border: 1px solid blue;
  }

  tbody {
    border: 1px solid green;
    width: 100%;
  }
  td {
    padding: 0.2rem;
  }

  td.author {
    text-align: right;
  }
}

.self {
  background-color: #e0e0e0;
}

.bot {
  font-family: monospace;
}
