.logo {
  position: relative;
  pointer-events: none;

  // Ensure the logo does not show a solid block when loading.
  color: transparent;
  background-color: transparent;
}

// .logo {
//   filter: grayscale(1);
// }
//
// @media (prefers-color-scheme: dark) {
//   .logo {
//     filter: invert(1) drop-shadow(0 0 0.3rem #ffffff70);
//   }
// }
