.sidebar {
  min-width: 240px;
  display: flex;
  // Match the padding of the PageLayoutPane, given as "4"…which somehow corresponds to 24px.
  // Controlled by its internal SPACING_MAP var, I guess.
  padding: 1.5rem;
  padding-bottom: 0;
  justify-items: end;
}

.main {
  // Fill vertically.
  flex: 1;

  // Center the content.
  display: flex;
}

.main {
  --logo-width: 320px;
  --logo-height: calc(var(--logo-width) - 8rem);
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 4rem 0;
}

.logo::before {
  border-radius: 50%;
  background: var(--primary-glow);
  width: var(--logo-width);
  height: var(--logo-height);
  content: '';
  position: absolute;
  filter: blur(45px);
  transform: translateZ(0);
}

/* Mobile */
@media (max-width: 700px) {
  .main {
    --logo-width: 300px;
  }

  .logo {
    padding: 8rem 0 6rem;
  }

  .logo::before {
    transform: none;
  }
}
